import React from "react"
import { FormattedMessage } from "react-intl"

// Assets
import DownloadIcon from "../../../images/icons/download.inline.svg"
import HeartIcon from "../../../images/icons/heart.inline.svg"
import ShieldIcon from "../../../images/icons/shield.inline.svg"
import StarLargeIcon from "../../../images/icons/star-large.inline.svg"

const BenefitsDesktop = () => {
  return (
    <div className="hidden md:grid grid-cols-4 gap-6 pt-8 items-center">
      <div className="flex items-center justify-center">
        <HeartIcon />
        <div className="flex ml-3 flex-col">
          <span className="text-sm font-semibold uppercase">
            <FormattedMessage id="benefit1FirstWord" />
          </span>
          <span className="uppercase">
            <FormattedMessage id="benefit1SecondWord" />
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <StarLargeIcon />
        <div className="flex ml-3 flex-col">
          <span className="text-sm font-semibold uppercase">
            <FormattedMessage id="benefit5FirstWord" />
          </span>
          <span className="uppercase">
            <FormattedMessage id="benefit5SecondWord" />
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <ShieldIcon />
        <div className="flex ml-3 flex-col">
          <span className="text-sm font-semibold uppercase">
            <FormattedMessage id="benefit2FirstWord" />
          </span>
          <span className="uppercase">
            <FormattedMessage id="benefit2SecondWord" />
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <DownloadIcon />
        <div className="flex ml-3 flex-col">
          <span className="text-sm font-semibold uppercase">
            <FormattedMessage id="benefit4FirstWord" />
          </span>
          <span className="uppercase">
            <FormattedMessage id="benefit4SecondWord" />
          </span>
        </div>
      </div>
    </div>
  )
}

export default BenefitsDesktop
